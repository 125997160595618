<template>
  <div class="dashboard">
    <section class="wallet-section section-space-b">
      <div class="container">
        <div class="row g-gs">
          <div class="col-lg-3 col-md-6 text-center">
            <div class="card-body card-body-s1 d-block card">
              <img class="mb-4 dash-icon" :src="require('@/images/tmp/money.png')" alt="balance">
              <h3 class="notranslate card-title mb-3 card-num">
                ${{ dashBoard && dashBoard.balance }}
              </h3>
              <h4 class="card-title mb-3">Available Balance</h4>
<!--              <span class="btn btn-dark"> Connect </span>-->
            </div><!-- end card-body -->
          </div><!-- end col -->
          <div class="col-lg-3 col-md-6 text-center">
            <div class="card-body card-body-s1 d-block card">
              <img class="mb-4 dash-icon" :src="require('@/images/tmp/free-trial1.png')" alt="trial">
              <h3 class="notranslate card-title mb-3 card-num">
                ${{ dashBoard && dashBoard.trialBalance }}
              </h3>
              <h4 class="card-title mb-3">Trial Funds</h4>
<!--              <p class="card-text card-text-s1 mb-4">$50</p>-->
              <!--              <span class="btn btn-dark"> Connect </span>-->
            </div><!-- end card-body -->
          </div><!-- end col -->

          <div class="col-lg-3 col-md-6 text-center">
            <div class="card-body card-body-s1 d-block card">
              <img class="mb-4 dash-icon" :src="require('@/images/tmp/deposit1.png')" alt="deposit">
              <h3 class="notranslate card-title mb-3 card-num">
                ${{ dashBoard.deposits ? dashBoard.deposits.toFixed(2) : '0.00' }}
              </h3>
              <h4 class="card-title mb-3">Total Deposit</h4>
              <!--              <span class="btn btn-dark"> Connect </span>-->
            </div><!-- end card-body -->
          </div><!-- end col -->

          <div class="col-lg-3 col-md-6 text-center">
            <div class="card-body card-body-s1 d-block card">
              <img class="mb-4 dash-icon" :src="require('@/images/tmp/withdraw.png')" alt="withdraw">
              <h3 class="notranslate card-title mb-3 card-num">
                - ${{ dashBoard.withdrawals ? - dashBoard.withdrawals.toFixed(2) : '0.00' }}
              </h3>
              <h4 class="card-title mb-3">Total Withdraw</h4>
              <!--              <span class="btn btn-dark"> Connect </span>-->
            </div><!-- end card-body -->
          </div><!-- end col -->

<!--          <div class="col-lg-3 col-md-6 text-center">-->
<!--            <div class="card-body card-body-s1 d-block card">-->
<!--              <img class="mb-4 dash-icon" :src="require('@/images/tmp/profit.png')" alt="t-profit">-->
<!--              <h4 class="card-title mb-3">Today Profits</h4>-->
<!--              <p class="card-text card-text-s1 mb-4">$50</p>-->
<!--              &lt;!&ndash;              <span class="btn btn-dark"> Connect </span>&ndash;&gt;-->
<!--            </div>&lt;!&ndash; end card-body &ndash;&gt;-->
<!--          </div>&lt;!&ndash; end col &ndash;&gt;-->

          <div class="col-lg-4 col-md-6 text-center">
            <div class="card-body card-body-s1 d-block card">
              <img class="mb-4 dash-icon" :src="require('@/images/tmp/profit.png')" alt="profit">
              <h4 class="card-title mb-3">Total Profits</h4>
              <h3 class="notranslate card-title mb-3 card-num">
                ${{ dashBoard.profits ? dashBoard.profits.toFixed(2) : '0.00' }}
              </h3>
            </div><!-- end card-body -->
          </div><!-- end col -->

          <div class="col-lg-4 col-md-6 text-center">
            <div class="card-body card-body-s1 d-block card">
              <img class="mb-4 dash-icon" :src="require('@/images/tmp/commission.png')" alt="commission">
              <h4 class="card-title mb-3">Total Commission</h4>
              <h3 class="notranslate card-title mb-3 card-num">
                ${{ dashBoard.referrals ? dashBoard.referrals.toFixed(2) : '0.00' }}
              </h3>
            </div><!-- end card-body -->
          </div><!-- end col -->

          <div class="col-lg-4 col-md-6 text-center">
            <div class="card-body card-body-s1 d-block card">
              <img class="mb-4 dash-icon" :src="require('@/images/tmp/system1.png')" alt="system">
              <h4 class="card-title mb-3">System</h4>
              <h3 class="notranslate card-title mb-3 card-num">
                ${{ dashBoard.systems ? dashBoard.systems.toFixed(2) : '0.00' }}
              </h3>
            </div><!-- end card-body -->
          </div><!-- end col -->


          <!--          <div class="col-lg-4 col-md-6 text-center">-->
          <!--            <div class="card-body card-body-s1 d-block card">-->
          <!--              <img class="mb-4 dash-icon" :src="require('@/images/tmp/smart-contracts.png')" alt="contract">-->
          <!--              <h4 class="card-title mb-3">Contract</h4>-->
          <!--              <p class="card-text card-text-s1 mb-4">5</p>-->
          <!--&lt;!&ndash;              <span class="btn btn-dark"> Connect </span>&ndash;&gt;-->
          <!--            </div>&lt;!&ndash; end card-body &ndash;&gt;-->
          <!--          </div>&lt;!&ndash; end col &ndash;&gt;-->

          <div class="h3">Affiliates Link</div>

          <div class="position-relative">
            <input type="text" class="notranslate form-control form-control-s1" placeholder="" v-model="path" disabled>
            <a href="javascript:void(0)" class="copy-toggle" title="Copy Link" v-clipboard:copy="path"
               v-clipboard:success="copysuccess" v-clipboard:error="copyerror">
              <em class="ni ni-copy-fill"></em>
            </a>
<!--            d-none -->
            <div class="d-sm-block" size="sm" style="margin-left:5px;" v-clipboard:copy="path"
                 v-clipboard:success="copysuccess" v-clipboard:error="copyerror">
              Copy Link
            </div>
          </div>

          <div class="col-lg-4 col-md-6 text-center">
            <div class="card-body card-body-s1 d-block card">
              <img class="mb-4 dash-icon" :src="require('@/images/tmp/handshake.png')" alt="system">
              <h4 class="card-title mb-3">Partners</h4>
              <h3 class="notranslate card-title mb-3">
                {{ dashBoard.partners || 0 }}
              </h3>
            </div><!-- end card-body -->
          </div><!-- end col -->

        </div><!-- row -->
      </div><!-- .container -->
    </section><!-- end wallet-section -->

  </div>
</template>

<script>
import storage from "@/storage";
import indexApi from "@/apis/indexApi";
import referralApi from "@/apis/referralApi";
import walletApi from "@/apis/walletApi";
import SectionData from "@/store/store";

export default {
  name: "Dashboard",
  data() {
    return {
      SectionData,
      showBound: false,
      withdrawBound: {
        isShow: 0,
        title: '',
        toVipAmount: 0,
        depositAmount: 0,
        remainingAmount: 0,
        remark: ''
      },
      login: false,
      userInfo: {},
      dashBoard: {
        // vue.runtime.esm.js:1888 TypeError: Cannot read properties of undefined (reading 'profits')
        profits: null
      },
      loading: false,
      referralData: '',
      path: ''
    }
  },
  mounted() {
    let userId = storage.localGet("userId")
    this.userInfo = userId
    if (userId) {
      this.login = true
      this.path = location.origin + '/sign-up';
      if(location.origin=== 'https://gdmining.us' ||
          location.origin=== 'https://gdmining.uk' ||
          location.origin=== 'https://gdmining.site' ||
          location.origin=== 'https://gdmining.org'){
        this.path = 'https://gdmining.com' + '/sign-up';
      }
      //获取信息
      referralApi.detail(res => {
        this.referralData = res.data
        this.path = this.path + "?ref=" + res.data.code
      })
      //获取仪表盘信息
      this.loading = true;
      indexApi.dashBoard(res => {
        this.loading = false;
        if (res.code !== 200) {
          storage.localDel("userId")
          this.login = false
        } else {
          this.dashBoard = res.data
        }
      })
    }
  },
  created() {
    walletApi.preWithdrawCheck(res => {
      if (res.data !== 'pass') {
        let extraJson = res.data.extraJson
        if (extraJson !== null && extraJson !== '') {
          let innerBound = {
            isShow: extraJson.isShow || 0,
            title: extraJson.title || '',
            toVipAmount: extraJson.toVipAmount || 0,
            depositAmount: extraJson.depositAmount || 0,
            remainingAmount: extraJson.remainingAmount || 0,
            remark: extraJson.remark || ''
          }
          try {
            const parsedJson = JSON.parse(extraJson)
            innerBound = {...innerBound, ...parsedJson}
            if (innerBound.isShow === 1) {
              this.showBound = true
            } else {
              this.showBound = false
            }
          } catch (error) {
            console.error('Invalid JSON string:', error)
          }
          this.withdrawBound = innerBound
        }
      }
    })
  },
  methods: {
    copysuccess() {
      this.$message.success("Copied")
    },
    copyerror() {
      this.$message.success("Fail")
    }
  }
}
</script>

<style scoped lang="scss">


.card{
  //box-shadow: 0 1px 20px rgba(24, 24, 24, 0.07);
  //box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 30px rgba(24, 24, 24, 0.15);
  outline: 2px solid darkorange;
}

.dash-icon {
  width: 80px;
}

.copy-toggle {
  position: absolute;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0.938rem 1.25rem;
}

.card-num {
  color: #0d6efd;
}


.form-control:disabled {
  //background-color: var(--bs-secondary-bg);
  background-color: white;
}

.row-i {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  padding: .75rem 1.375rem;

  &.row2 {
    .item {
      color: #1C2A46;
    }
  }

  &.row3 {
    box-shadow: none;
    padding: 0.5rem 0;
    margin-left: calc(var(--bs-gutter-x) * -1);
    margin-right: calc(var(--bs-gutter-x) * -1);

    .item {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
      border-radius: 0.5rem;
      color: #1C2A46;
      padding: 1.25rem 1rem;
      margin: 0.5rem 0;
    }
  }

  .row4 {

  }
}

.relink_box {
  margin-top: 6px;
}

.relink {
  //width: 370px;
  height: 35px;
  border-radius: 8px;
  opacity: 0.8;
  border: 1px solid #3A516C;
  display: flex;
  align-items: center;


  .path {
    flex: 1;
    width: 0;
    font-size: 13px;
    //font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1C2A46;
    line-height: 18px;
    padding: 0 13px;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
  }

  .button {
    //width: 32px;
    padding: 0 13px;
    //height: 20px;
    font-size: 14px;
    //font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #00B301;
    line-height: 26px;
    cursor: pointer;
  }
}

.item {
  color: #FFFFFF;
  margin: 0.5rem 0;
  height: 100%;
  font-weight: 600;
  //margin-left: 0 !important;
  //margin-right: 0 !important;
  .label {
    //font-size: 0.875rem;
    font-size: 1.1rem;
    //font-family: PingFangSC-Medium, PingFang SC;

    line-height: 1.25rem;
  }

  .value {
    font-size: 1.5rem;
    //font-family: PingFangSC-Medium, PingFang SC;
    line-height: 2.125rem;
  }
}

.btn {
  //padding: 0.5rem 1.25rem;
  //line-height: 1.25rem;
}

.btn-deposit {
  background-color: #FFFFFF;
  border-radius: 0.25rem;
  color: #0a0a0a;
  font-size: 0.8rem;

  &:hover {
    //background-color: rgba(32, 136, 87, .9);
    background-color: #3A516C;
    color: #ffffff;
  }
}

.btn-withdraw {
  background-color: #FFFFFF;
  border-radius: 0.25rem;
  color: #0a0a0a;
  font-size: 0.8rem;

  &:hover {
    //background-color: rgba(58, 81, 108, .9);
    background-color: #3A516C;
    color: #ffffff;
  }
}

.form {
  //width: 600px;
  //height: 370px;
  background: #F8F9FC;
  border-radius: 0.5rem;
  padding: 1.3125rem;
  margin-bottom: 8px;

  .item1 {
    &-label {
      height: 1.3125rem;
      font-size: .9375rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333947;
      line-height: 1.3125rem;
    }

    &-control {
      margin-top: 0.625rem;

      input {
        width: 100%;
        height: 2.6875rem;
        border: 1px solid #FFFFFF;
        padding: 0 1rem;
        background: #FFFFFF;
        border-radius: 4px;
        //height: 1.3125rem;
        font-size: .9375rem;
        //font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1C2A46;
        line-height: 2.6875rem;
        //caret-color:  rgba(var(--bs-link-color-rgb));
        transition: all .3s ease;

        &:focus {
          outline: none;
          border: 1px solid rgba(var(--bs-link-color-rgb));
        }

        &::-webkit-input-placeholder {
          /* WebKit browsers，webkit内核浏览器 */
          color: #8091A7;
        }

        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #8091A7;

        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #8091A7;

        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #8091A7;

        }


      }


      .select {
        //margin: 0;
        &-i {
          background: #FFFFFF;
          border-radius: 4px;
          padding: 1.25rem 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid transparent;
          transition: all 0.3s ease;

          &.active {
            border-color: rgba(var(--bs-link-color-rgb));
          }

          span {
            margin-top: 0.625rem;
            font-size: 0.9375rem;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1C2A46;
            line-height: 1.3125rem;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 992px) {
  .row2 {
    //padding: 0;
    padding: 0.25rem 0;
    box-shadow: none;

    .item {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
      border-radius: 0.5rem;
      color: #1C2A46;
      padding: 1.5rem 1rem;
      margin: 0.25rem 0;
      border: 1px solid #3A516C;
    }
  }
  .row3 {
    .item {
      border: 1px solid #3A516C;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
